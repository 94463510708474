export const countryCurrencies = {
    // Andorra
    ad: 'EUR',
    // United Arab Emirates
    ae: 'AED',
    // Afghanistan
    af: 'AFN',
    // Antigua and Barbuda
    ag: 'XCD',
    // Anguilla
    ai: 'XCD',
    // Albania
    al: 'ALL',
    // Armenia
    am: 'AMD',
    // Angola
    ao: 'AOA',
    // Argentina
    ar: 'ARS',
    // Austria
    at: 'EUR',
    // Australia
    au: 'AUD',
    // Aruba
    aw: 'AWG',
    // Azerbaijan
    az: 'AZN',
    // Bosnia and Herzegovina
    ba: 'BAM',
    // Barbados
    bb: 'BBD',
    // Bangladesh
    bd: 'BDT',
    // Belgium
    be: 'EUR',
    // Burkina Faso
    bf: 'XOF',
    // Bulgaria
    bg: 'BGN',
    // Bahrain
    bh: 'BHD',
    // Burundi
    bi: 'BIF',
    // Benin
    bj: 'XOF',
    // Bermuda
    bm: 'BMD',
    // Brunei Darussalam
    bn: 'SGD',
    // Bolivia
    bo: 'BOB',
    // Brazil
    br: 'BRL',
    // Bahamas
    bs: 'BSD',
    // Bhutan
    bt: 'INR',
    // Botswana
    bw: 'BWP',
    // Belarus
    by: 'BYN',
    // Belize
    bz: 'BZD',
    // Canada
    ca: 'CAD',
    // Democratic Republic of the Congo
    cd: 'CDF',
    // Central African Republic
    cf: 'XAF',
    // Republic of the Congo
    cg: 'XAF',
    // Switzerland
    ch: 'CHF',
    // Côte d'Ivoire
    ci: 'XOF',
    // Cook Islands
    ck: 'NZD',
    // Chile
    cl: 'CLP',
    // Cameroon
    cm: 'XAF',
    // China (mainland)
    cn: 'CNY',
    // Colombia
    co: 'COP',
    // Costa Rica
    cr: 'CRC',
    // Cuba
    cu: 'CUP',
    // Cabo Verde
    cv: 'CVE',
    // Curaçao
    cw: 'ANG',
    // Cyprus
    cy: 'EUR',
    // Czech Republic
    cz: 'CZK',
    // Germany
    de: 'EUR',
    // Djibouti
    dj: 'DJF',
    // Denmark
    dk: 'DKK',
    // Dominica
    dm: 'XCD',
    // The Dominican Republic
    do: 'DOP',
    // Algeria
    dz: 'DZD',
    // Ecuador
    ec: 'USD',
    // Estonia
    ee: 'EUR',
    // Egypt
    eg: 'EGP',
    // Eritrea
    er: 'ERN',
    // Spain
    es: 'EUR',
    // Ethiopia
    et: 'ETB',
    // Finland
    fi: 'EUR',
    // Fiji
    fj: 'FJD',
    // Micronesia
    fm: 'USD',
    // France
    fr: 'EUR',
    // Gabon
    ga: 'XAF',
    // United Kingdom
    gb: 'GBP',
    // Grenada
    gd: 'XCD',
    // Georgia
    ge: 'GEL',
    // Ghana
    gh: 'GHS',
    // Gibraltar
    gi: 'GIP',
    // Greenland
    gl: 'DKK',
    // Gambia
    gm: 'GMD',
    // Guinea
    gn: 'GNF',
    // Guadeloupe
    gp: 'EUR',
    // Equatorial Guinea
    gq: 'XAF',
    // Greece
    gr: 'EUR',
    // Guatemala
    gt: 'GTQ',
    // Guinea-Bissau
    gw: 'XOF',
    // Guyana
    gy: 'GYD',
    // Hong Kong
    hk: 'HKD',
    // Honduras
    hn: 'HNL',
    // Croatia
    hr: 'HRK',
    // Haiti
    ht: 'HTG',
    // Hungary
    hu: 'HUF',
    // Indonesia
    id: 'IDR',
    // Ireland
    ie: 'EUR',
    // Israel
    il: 'ILS',
    // India
    in: 'INR',
    // Iraq
    iq: 'IQD',
    // Iran
    ir: 'IRR',
    // Iceland
    is: 'ISK',
    // Italy
    it: 'EUR',
    // Jamaica
    jm: 'JMD',
    // Jordan
    jo: 'JOD',
    // Japan
    jp: 'JPY',
    // Kenya
    ke: 'KES',
    // Kyrgyzstan
    kg: 'KGS',
    // Cambodia
    kh: 'KHR',
    // Kiribati
    ki: 'AUD',
    // The Comoros
    km: 'KMF',
    // Saint Kitts and Nevis
    kn: 'XCD',
    // The Democratic People's Republic of Korea
    kp: 'KPW',
    // The Republic of Korea
    kr: 'KRW',
    // Kuwait
    kw: 'KWD',
    // Cayman Islands
    ky: 'KYD',
    // Kazakhstan
    kz: 'KZT',
    // Lao People's Democratic Republic
    la: 'LAK',
    // Lebanon
    lb: 'LBP',
    // Saint Lucia
    lc: 'XCD',
    // Liechtenstein
    li: 'CHF',
    // Sri Lanka
    lk: 'LKR',
    // Liberia
    lr: 'LRD',
    // Lesotho
    ls: 'ZAR',
    // Lithuania
    lt: 'EUR',
    // Luxembourg
    lu: 'EUR',
    // Latvia
    lv: 'EUR',
    // Libya
    ly: 'LYD',
    // Morocco
    ma: 'MAD',
    // Monaco
    mc: 'EUR',
    // Moldova
    md: 'MDL',
    // Montenegro
    me: 'EUR',
    // Madagascar
    mg: 'MGA',
    // Marshall Islands
    mh: 'USD',
    // North Macedonia
    mk: 'MKD',
    // Mali
    ml: 'XOF',
    // Myanmar
    mm: 'MMK',
    // Mongolia
    mn: 'MNT',
    // Macao
    mo: 'HKD',
    // Martinique
    mq: 'EUR',
    // Mauritania
    mr: 'MRU',
    // Montserrat
    ms: 'XCD',
    // Malta
    mt: 'EUR',
    // Mauritius
    mu: 'MUR',
    // Maldives
    mv: 'MVR',
    // Malawi
    mw: 'MWK',
    // Mexico
    mx: 'MXN',
    // Malaysia
    my: 'MYR',
    // Mozambique
    mz: 'MZN',
    // Namibia
    na: 'ZAR',
    // New Caledonia
    nc: 'XPF',
    // Niger
    ne: 'XOF',
    // Nigeria
    ng: 'NGN',
    // Nicaragua
    ni: 'NIO',
    // Netherlands
    nl: 'EUR',
    // Norway
    no_: 'NOK',
    // Nepal
    np: 'NPR',
    // Nauru
    nr: 'AUD',
    // New Zealand
    nz: 'NZD',
    // Oman
    om: 'OMR',
    // Panama
    pa: 'USD',
    // Peru
    pe: 'PEN',
    // French Polynesia
    pf: 'XPF',
    // Papua New Guinea
    pg: 'USD',
    // The Philippines
    ph: 'PHP',
    // Pakistan
    pk: 'PKR',
    // Poland
    pl: 'PLN',
    // Puerto Rico
    pr: 'USD',
    // Palestine
    ps: 'ILS',
    // Portugal
    pt: 'EUR',
    // Palau
    pw: 'USD',
    // Paraguay
    py: 'PYG',
    // Qatar
    qa: 'QAR',
    // Réunion
    re: 'EUR',
    // Romania
    ro: 'RON',
    // Serbia
    rs: 'RSD',
    // Russia
    ru: 'RUB',
    // Rwanda
    rw: 'RWF',
    // Saudi Arabia
    sa: 'SAR',
    // Solomon Islands
    sb: 'SBD',
    // Seychelles
    sc: 'SCR',
    // Sudan
    sd: 'SDG',
    // Sweden
    se: 'SEK',
    // Singapore
    sg: 'SGD',
    // Slovenia
    si: 'EUR',
    // Slovakia
    sk: 'EUR',
    // Sierra Leone
    sl: 'SLL',
    // San Marino
    sm: 'EUR',
    // Senegal
    sn: 'XOF',
    // Somalia
    so: 'SOS',
    // Suriname
    sr: 'SRD',
    // South Sudan
    ss: 'SSP',
    // Sao Tome and Principe
    st: 'STN',
    // El Salvador
    sv: 'USD',
    // Syria
    sy: 'SYP',
    // Eswatini
    sz: 'ZAR',
    // Turks and Caicos Islands
    tc: 'USD',
    // Chad
    td: 'XAF',
    // Togo
    tg: 'XOF',
    // Thailand
    th: 'THB',
    // Tajikstan
    tj: 'TJS',
    // Timor-Leste
    tl: 'USD',
    // Turkmenistan
    tm: 'TMT',
    // Tunisia
    tn: 'TND',
    // Tonga
    to: 'TOP',
    // Turkey
    tr: 'TRY',
    // Trinidad and Tobago
    tt: 'TTD',
    // Tuvalu
    tv: 'AUD',
    // Taiwan
    tw: 'TWD',
    // Tanzania
    tz: 'TZS',
    // Ukraine
    ua: 'UAH',
    // Uganda
    ug: 'UGX',
    // United States
    us: 'USD',
    // Uruguay
    uy: 'UYU',
    // Uzbekistan
    uz: 'UZS',
    // Vatican City
    va: 'EUR',
    // Saint Vincent and the Grenadines
    vc: 'XCD',
    // Venezuela
    ve: 'VES',
    // Vietnam
    vn: 'VND',
    // Vanuatu
    vu: 'VUV',
    // Samoa
    ws: 'WST',
    // Yemen
    ye: 'YER',
    // South Africa
    za: 'ZAR',
    // Zambia
    zm: 'ZMW',
    // Zimbabwe
    zw: 'ZWB',
};
